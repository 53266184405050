export const useCallcenterData = () => useState("callcenterData", () => {
    return ref({
        monthly_balance: 0,
        topup_balance: 0,
        mode: '', // Add other necessary default properties
        pin_code: '',
        topup_allowed: false
    });
});

export const useOriginalCallcenterData = () => useState("originalCallcenterData", () => {
    return ref({});
});